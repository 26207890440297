import Vue from 'vue'
import VueRouter from 'vue-router'
import { firebase, db } from '@/main'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home.vue'),
  }, {
    path: '/auth/sign_in',
    name: 'SignIn',
    component: () => import('../views/auth/sign_in.vue'),
    meta: { isPublic: true }
  }, {
    path: '/polls/:poll_id',
    name: 'PollsShow',
    component: () => import('../views/polls/show.vue')
  }, {
    path: '/manage',
    name: 'Manage',
    redirect: '/manage/export_vote'
  }, {
    path: '/manage/export_vote',
    name: 'ManageExportVote',
    component: () => import('../views/manage/export_vote.vue'),
    meta: { requiresAdmin: true }
  }, {
    path: '/manage/import_account',
    name: 'ManageImportAccount',
    component: () => import('../views/manage/import_account.vue'),
    meta: { requiresAdmin: true }
  }, {
    path: '/manage/import_user',
    name: 'ManageImportUser',
    component: () => import('../views/manage/import_user.vue'),
    meta: { requiresAdmin: true }
  },

  // error
  {
    path: '*',
    name: 'error',
    component: () => import('../views/error.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'is-active',
  routes
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.isPublic)
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)
  const requiresOrganizer = to.matched.some(record => record.meta.requiresOrganizer)

  firebase.auth().onAuthStateChanged((user) => {
    if (isPublic) {
      // 認証不要
      next()
    } else {
      // 認証が必要
      if (!user) {
        // 未ログイン
        store.commit('user', null)
        next({ name: 'SignIn', query: { redirect: to.fullPath } })
      } else {
        // ログイン済み
        db.collection('users').doc(user.uid).get().then((doc) => {
          const data = doc.data()

          const userContents = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            department: (data ? data.department : "")  // usersにデータが存在しない場合はdataがundefinedになる（通常は発生しない）
          }
          store.commit('user', userContents)

          user.getIdToken(true).then(() => {
            user.getIdTokenResult().then((idTokenResult) => {
              const claims = idTokenResult.claims
              const isAdmin = claims.admin
              const isOrganizer = claims.organizer

              if (requiresAdmin && !isAdmin) {
                next({ name: 'error', query: { code: 'unauthorized' } })
              } else if (requiresOrganizer && !isAdmin && !isOrganizer) {
                next({ name: 'error', query: { code: 'unauthorized' } })
              } else {
                next()
              }
            })
          })
        })
      }
    }
  })
})

export default router
