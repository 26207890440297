<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<script>

export default {
  name: 'app'
}
</script>


<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap');
@import "./assets/css/buefy.scss";
@import "./assets/css/application.scss";
@import "./assets/css/bulma/elements/button.css";
</style>
