<template>
  <div class="debug" v-if="isShow">
    <span class="content-title">{{ title ? title : "debug" }}</span>
    <div class="pre" v-if="type == 'textarea'"><slot /></div>
    <div v-else><slot /></div>
  </div>
</template>


<script>
export default {
  props: ['type', 'title'],
  data() {
    return {
      isShow: true
    }
  },
}
</script>


<style lang="scss" scoped>
.debug {
  background-color: whitesmoke;
  border-radius: 3px;
  font-size: 0.75em;
  padding: 1.5rem 1rem 1rem 1rem;
  position: relative;
  min-height: 2rem;
  max-height: 10rem;
  overflow-y: scroll;
  margin-bottom: 0.5rem;

  textarea {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
  }

  .content-title {
    background-color: #dbdbdb;
    border-top-left-radius: 3px;
    font-size: 0.75em;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.15rem 0.3rem;
  }
}

.pre {
  white-space: pre;
}
</style>
