import Vue from 'vue'
import Vuex from 'vuex'
import VueCookie from 'vue-cookie'
import App from './app.vue'
import router from './router'
import store from './store'


// Firebase
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD-2kI3lFVXsI_ctCzwnE37vUF45ENMxdY",
  authDomain: "award.shiftlab.tech",
  projectId: "shift-award",
  storageBucket: "shift-award.appspot.com",
  messagingSenderId: "353967231844",
  appId: "1:353967231844:web:1cd297f0f81e81606f6071",
  measurementId: "G-SWXM282ZB6"
};

firebase.initializeApp(firebaseConfig)

const db = firebase.firestore()
const auth = firebase.auth()
export { firebase, db, auth }

import { firestorePlugin } from 'vuefire'
Vue.use(firestorePlugin)


// Fontawsome
import { dom } from '@fortawesome/fontawesome-svg-core'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
dom.watch() // This will kick of the initial replacement of i to svg tags and configure a MutationObserver
library.add(fas, far, fab)
Vue.component('fa-icon', FontAwesomeIcon)


// Buefy
import Buefy from 'buefy'
Vue.use(Buefy, {
  defaultIconPack: 'fas',
})


// Tippy
import VueTippy, { TippyComponent } from 'vue-tippy'
Vue.use(VueTippy, {})
Vue.component('tippy', TippyComponent)

import "tippy.js/themes/light-border.css"


// Toasted
import Toasted from 'vue-toasted'
Vue.use(Toasted, {
  theme: 'toasted-primary',
  position: 'bottom-center',
  containerClass: 'is-edge',
  iconPack: 'custom-class',
  duration : 5000
})


// Dayjs
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.locale('ja')
dayjs.extend(localizedFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(isBetween)
Vue.prototype.$date = dayjs

Vue.prototype.$today = () => { return new Date(new Date().setHours(0, 0, 0, 0)) }
Vue.prototype.$dateRange = (startDate, endDate, step=1, unit='day') => {
  const start = dayjs(startDate)
  const end = dayjs(endDate)
  if (!start.isSameOrBefore(end)) return []
  let current = dayjs(startDate)
  const dates = [current.toDate()]

  while (current.isBetween(start, end, null, '[)')) {
    current = current.add(step, unit)
    if (current.isSameOrBefore(end)) {
      dates.push(current.toDate())
    }
  }
  return dates
}


// JsonCSV
import JsonCSV from 'vue-json-csv'
Vue.component('download-csv', JsonCSV)


// Twemoji
import twemoji from 'twemoji'
Vue.directive('twemoji', {
  inserted(el) {
    el.innerHTML = twemoji.parse(el.innerHTML)
  }
})


// VueLazyload
import VueLazyload from 'vue-lazyload'

const loadimage = require('./assets/image/avator.png')

Vue.use(VueLazyload, {
  loading: loadimage
})


// Role
import { can } from '@/utils/acl'
Vue.prototype.$can = (...args) => can(store.state.user, ...args)


// Filters
import * as filters from './filters'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
})


// Mixins
import titleMixin from './utils/title'
Vue.mixin(titleMixin)


// Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueCookie)
Vue.config.productionTip = false

import DebugLog from './components/debug_log'
Vue.component('debug-log', DebugLog)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
